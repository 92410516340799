@use 'sass:map';
@use '@angular/material' as mat;
@import '~@angular/cdk/overlay-prebuilt.css';
@import "variables";
@import "helpers";
@import "material_override";

html, body {
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.text-pre-line { white-space: pre-line; }
.text-center { text-align: center; }
.no-wrap { white-space: nowrap; }
.full-width { width: 100%; }
.pointer { cursor: pointer; }

// TODO: Move to styles/theme
$light-primary-palette: mat.$indigo-palette;
$light-accent-palette: mat.$pink-palette;
$light-warn-palette: mat.$red-palette;

// TODO: Move to styles/theme
:root {
  --apfr-primary-color: #{map.get($light-primary-palette, 500)};
  --apfr-accent-color: #{map.get($light-accent-palette, 500)};
  --apfr-warn-color: #{map.get($light-warn-palette, 500)};
}

.text-primary { color: var(--apfr-primary-color) !important; }
.text-accent { color: var(--apfr-accent-color) !important; }
.text-warn { color: var(--apfr-warn-color) !important; }

// TODO: Move to styles/theme/mat-components/mat-form.scss
.mat-suffix-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.6em;
  color: currentColor;

  &:not(.in-request) {
    .mat-progress-spinner {
      opacity: 0;
    }
  }
}


.mobile-agenda-event-info {
  min-height: 60vh;
}

@media screen and (max-width: 599px) {
  header > .mat-toolbar {
    font-size: 17px;
  }

  .selectBoxInModal {
    min-width: calc(100% + #{$base-padding-sm}) !important;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  padding: 100px;
}

.form-header {
  display: flex;
  justify-content: space-between;
}

//TODO: we shouldnt use styles from apfr-sources

/* You can add global styles to this file, and also import other style files */
$loading-color: #E4E6EB;
$arrow-size: 35px;

table {
  width: 100%;
}

.countable-item {
  .color {
    display: block;
    width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 50%;
  }

  .name-cel {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
  }
}

.base-spinner {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  z-index: 100;
}

.multiline-snack {
  white-space: pre-wrap;
}

.mat-date-range-picker-actions {
  display: flex;
  align-items: baseline;
  gap: 1rem;
}

.loading {
  animation-name: loading-animation;
  animation-timing-function: steps(10, end);
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1s;
}

.loading-background {
  background-color: $loading-color !important;
}

.text-center {
  text-align: center;
}

.loading-color {
  color: $loading-color !important;
}

@keyframes loading-animation {
  0% {
    opacity: .25
  }
  100% {
    opacity: 1
  }
}

div:not(.color-picker) > {
  .arrow {
    font-size: $arrow-size;
    height: $arrow-size !important;
    width: $arrow-size !important;
    cursor: pointer;

    &:active {
      transform: scale(0.9);
    }
  }

}

.content .mat-card {
  height: calc(100% - 32px);
}


.component-wrapper {
  background-color: white;
}


.calendar-form-dialog {
  .mat-dialog-content {
    height: calc(100% - 64px);
    max-height: 100%;
    overflow-y: hidden;
  }
}

@media screen and (max-width: 599px) {
  .calendar-form-dialog {
    .mat-dialog-content {
      align-items: center;
      height: calc(100% - 35px);
    }
  }
}

.half-width {
  width: calc(50% - 5px);
}

.half-width-container {
  display: flex;
  justify-content: space-between;
}

.half-width + .half-width {
  margin-left: 10px;
}

.cdk-overlay-container {
  z-index: 2000 !important;
}
.timepicker-overlay {
  z-index: 2001!important;
}

.w-100{
  width: 100%;
}

.mt-5{
  margin-top: 5px;
}
