.spacer {
  flex: 1 1 auto;
}

[hidden] {
  visibility: hidden;
  width: 0;
  height: 0;
  display: none !important;
}

.hidden-iframe {
  position: absolute;
  bottom: 0;
  width: 1px;
  height: 1px;
}

.position-relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.reset-margin {
  margin: 0;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-right-25 {
  margin-right: 25px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.max-width-350 {
  max-width: 350px;
}

.max-width-100 {
  max-width: 100px;
}

.min-width-100 {
  min-width: 100px;
}

.max-width-50 {
  max-width: 50px;
}

.p-20 {
  padding: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-10 {
  margin-top: 5px;
}

.pl-15 {
  padding-left: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

[hidden] {
  display: none !important;
}

.d-inline {
  display: inline-block;
}

.d-block {
  display: block !important;
}

.m-auto {
  margin: auto 0;
}

.to-left {
  float: left;
}

.valid_icon {
  color: mat-color((mat-palette($mat-green)));
}

.invalid_icon {
  color: mat-color((mat-palette($mat-red)));
}

iframe, .iframe-container {
  border: none;
  width: 100%;
  height: 100%
}


.full-width .mat-checkbox-layout {
  display: block;
  width: 100%;
}

.flex-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-select {
  display: block;
  width: 100%;
  font-size: 16px;
  height: 200px;
}

.link-tel {
  color: inherit;
  text-decoration: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
