// Dialogs
.full-size-dialog {
  .mat-dialog-container {
    padding: $base-padding;
    border-radius: unset;
  }

  .mat-dialog-content {
    margin: 0 #{-$base-content-margin};
    padding: 0 $base-padding;
  }

  .mat-dialog-title {
    margin: #{-$base-content-margin} #{-$base-content-margin} $base-content-margin;
  }
}

.mat-option-ripple {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
}

// Calendar
.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: $accent;
}

.mat-calendar-body-cell.currentSelectedDate {
  .mat-calendar-body-cell-content {
    border-width: 1px;
    border-style: solid;
    border-radius: 999px;
    border-color: $primary;
  }
}

@media screen and (max-width: 599px) {
  // Dialogs
  .full-size-dialog {
    .mat-dialog-container {
      padding: $base-padding-sm;
      border-radius: unset;
    }

    .mat-dialog-content {
      margin: 0 #{-$base-content-margin-sm};
      padding: 0 $base-padding-sm;
      max-height: 100%;
      height: calc(100% - 56px);
    }

    .mat-dialog-title {
      margin: #{-$base-content-margin-sm} #{-$base-content-margin-sm} $base-content-margin-sm;
    }
  }
}
