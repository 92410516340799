@import '~@angular/material/theming';

$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink);
$app-warn: mat-palette($mat-red);

$primary: mat-color($app-primary);
$accent: mat-color($app-accent, A200);
$warn: mat-color($app-warn);

$active: green;
$inactive: gray;

$card-header-color: mat-color(mat-palette($mat-blue-grey));

$base-content-margin: 24px;
$base-content-margin-sm: 16px;

$base-padding: 24px;
// Use for mobile view
$base-padding-sm: 16px;

$header-width: 64px;
$header-width-sm: 56px;

@mixin alert-success {
  $color: $mat-green;
  color: mat-color((mat-palette($color, 900)));
  border-color: mat-color($color, 50);
  background-color: mat-color(mat-palette($color, 200));
}

@mixin alert-warn {
  $color: $mat-orange;
  color: mat-color((mat-palette($color, 900)));
  border-color: mat-color($color, 50);
  background-color: mat-color(mat-palette($color, 200));
}

@mixin alert-primary {
  $color: $mat-blue;
  color: mat-color((mat-palette($color, 900)));
  border-color: mat-color($color, 50);
  background-color: mat-color(mat-palette($color, 200));
}

@mixin alert-error {
  $color: $mat-red;
  color: mat-color((mat-palette($color, 900)));
  border-color: mat-color($color, 50);
  background-color: mat-color(mat-palette($color, 200));
}
